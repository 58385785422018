<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="row">
                        <div class="col-1">
                            <button type="button" class="btn btn-outline-warning mb-3"
                                :disabled="statusRefresh == 0 ? true : false" @click="ListLp()">
                                <i class="fas fa-undo-alt"></i>
                            </button>
                        </div>
                        <div class="col-2">
                            <button type="button" v-b-modal.modal-center
                                class="btn btn-outline-warning waves-effect waves-light" data-toggle="modal"
                                data-target=".bs-example-modal-center">
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
                <!-- End search -->
            </div>
            <b-modal id="modal-center" centered title="Filter" title-class="font-18" hide-footer>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <label>Date Select</label>
                            <flat-pickr class="form-control flatpickr-input" v-model="formData.date"
                                :config="multipleOption" placeholder="date"></flat-pickr>
                        </div>
                        <!-- selectbox pipeline -->
                        <div class="col-12">
                            <label>Pipeline</label>
                            <b-form-select v-model="formData.pipeline" :options="uniquePipeline" class="form-control">
                            </b-form-select>
                        </div>
                        <!-- selectbox pipeline -->
                        <div class="col-12">
                            <label>Utm Medium</label>
                            <b-form-select v-model="formData.utmMediumId" :options="uniqueUtmMediums"
                                class="form-control">
                            </b-form-select>
                        </div>
                        <div class="col-12 mt-4">
                            <button class="btn btn-outline-info" @click="dateSend()">
                                Gönder
                            </button>
                        </div>
                    </div>
                </div>
            </b-modal>
            <div class="row">
                <div class="col-12 table-responsive">
                    <table class="table table-bordered  table-fixed-header">
                        <thead>
                            <tr>
                                <th colspan="11" class="text-center table-primary">
                                    {{ formData.utmMediumId }} - {{ formData.dateNew }}
                                </th>
                            </tr>
                            <tr class="text-center table-primary">
                                <th>Kampanya Adı</th>
                                <th>WP CODE</th>
                                <th>HARCAMA</th>
                                <th>LEAD</th>
                                <th>CRM LEAD</th>
                                <th>LEAD MALİYETİ</th>
                                <th v-if="formData.pipeline == 'Rituel - Direct Sales'">NEGOTIATION</th>
                                <th v-else>Randevu Verildi</th>
                                <th>N/CRM L</th>
                                <th>SALES</th>
                                <th>InActive</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(dat, key) in orderData" :key="key">
                                <td>{{ dat.utm_campaign }}</td>
                                <td>{{ dat.utm_terms }}</td>
                                <td></td>
                                <td></td>
                                <td>{{ dat.total }}</td>
                                <td></td>
                                <td>{{ dat.randevu }}</td>
                                <td></td>
                                <td>{{ dat.satis }}</td>
                                <td></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="table-secondary">
                                <td colspan="2">TOPLAM</td>
                                <td></td>
                                <td></td>
                                <td>{{ selectedRangeLead }}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="col-12 col-lg-6 table-responsive lead-table" style="margin-top: 25px;">

                    <table class="table table-bordered  table-fixed-header">
                        <thead>
                            <tr class="text-center table-primary">
                                <th></th>
                                <th>Bütçe</th>
                                <th>LEAD</th>
                                <th>NEGOTIATION / Randevu Verildi</th>
                                <th>Satış</th>
                            </tr>
                        </thead>
                        <tr v-for="(dat, key) in leadMedium" :key="key">
                            <th>{{ dat.name }}</th>
                            <td></td>
                            <td>{{ dat.total }}</td>
                            <td>{{ dat.randevu }}</td>
                            <td>{{ dat.satis }}</td>
                        </tr>
                        <tfoot>
                            <tr class="table-secondary">
                                <td >TOPLAM</td>
                                <td></td>
                                <td>{{ selectedRangeLead }}</td> 
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import { phone } from "phone";
// import CountryFlag from 'vue-country-flag'
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";

export default {
    page: {
        title: "CRM Lead Medium Table",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: {
        Layout,
        PageHeader,
        flatPickr,
        // CountryFlag,
        // Multiselect,
    },
    data() {
        return {
            title: "CRM Lead Medium Table",
            items: [
                {
                    text: "CRM Lead Medium Table",
                },
                {
                    text: "Orders",
                    active: true,
                },
            ],
            orderData: [
                {
                    name: "Dönüş Yok",
                },
            ],
            statusValue: 1,
            statusRefresh: 1,
            filter: null,
            todayLead: null,
            yesterdayLead: null,
            selectedRangeLead: null,
            filterOn: [],
            sortBy: "age",
            isLoading: false,
            sortDesc: false,
            uniqueUtmMediums: [],
            uniquePipeline: [],
            leadMedium: [],
            statusPipe: [],
            multipleOption: {
                mode: "range",
                maxDate: "today",
                disableMobile: "true",
            },
            formData: {
                user: "estetikInternationalLead",
                password: "777_leadEstetikInternationalYetki",
            },
        };
    },
    computed: {
        rows() {
            return this.orderData.length;
        },

    },
    mounted() {
        this.totalRows = this.items.length;
        this.ListLp();
    },
    methods: {

        getStatusCount(statuses, statusName) {
            return statuses[statusName] || "-";
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        convertToFormData() {
            const _formData = new FormData();

            Object.keys(this.formData).map((key) => {
                _formData.append(key, this.formData[key]);
            });

            return _formData;
        },
        async ListLp() {
            this.isLoading = true;
            this.statusRefresh = 0;
            await API.Get(API.CRMLead.LeadMedium).then((response) => {
                this.orderData = response.data.orderData;
                var data;
                if (response.data.status === "success") {
                    this.uniquePipeline = response.data.allPipelines;
                    this.uniqueUtmMediums = response.data.uniqueUtmMedium;
                    this.selectedRangeLead = response.data.total;
                    this.formData.utmMediumId = response.data.utmMediumId;
                    this.formData.pipeline = response.data.selectedPipeline;
                    this.formData.dateNew = response.data.date;
                    this.leadMedium = response.data.leadMedium;

                    data = "Your work has been refresh";
                    POPUP.popupClick(data);
                    this.statusRefresh = 1;
                } else {
                    data = "Your work could not be renewed";
                    POPUP.popupClickNot(data);
                    this.statusRefresh = 1;
                }
            });
        },
        async dateSend() {
            this.isLoading = true;
            this.statusRefresh = 0;
            this.timerPopup();
            await API.Post(API.CRMLead.LeadMedium, this.convertToFormData()).then((response) => {
                this.orderData = response.data.orderData;
                console.log(response.data);
                var data;
                if (response.data.status === "success") {
                    this.uniquePipeline = response.data.allPipelines;
                    this.formData.utmMediumId = response.data.utmMediumId;
                    this.selectedRangeLead = response.data.total;
                    this.formData.dateNew = response.data.date;
                    this.leadMedium = response.data.leadMedium;
                    this.formData.pipeline = response.data.selectedPipeline;
                    data = "Your work has been refresh";
                    POPUP.popupClick(data);
                    this.statusRefresh = 1;
                } else {
                    data = "Your work could not be renewed";
                    POPUP.popupClickNot(data);
                    this.statusRefresh = 1;
                }
            });
        },

        timerPopup(timerClear = false) {
            let timerInterval;
            Swal.fire({
                position: "top-end",
                title: "Please wait to connect to CMS",
                timer: 3000,
                timerProgressBar: true,
                willClose: () => {
                    clearInterval(timerInterval);
                },
            });
            if (timerClear) {
                clearInterval(timerInterval);
            }
        },
    },
};
</script>


<style scoped>
.table-primary {
    font-weight: bold;
}

.table-secondary {
    background-color: #f8f9fa;
}

.table-responsive {
    max-height: 500px;
    overflow-y: auto;
}

.table-fixed-header thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table-fixed-header tfoot td {
    position: sticky;
    bottom: 0;
    z-index: 2;
}

.lead-table table {
    border-collapse: collapse;
    margin: 20px auto;
}


.lead-table th,
td {
    padding: 10px;
    text-align: center;
    border: 1px solid #fff;
}
</style>